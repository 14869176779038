@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

*{
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;	
}

body{
	color: $near-black;
	font-size: 14px;
}

p, ul, li{
	color: $near-black;
	font-weight: 500;
	font-size: 14px;
}

b, strong{
	font-weight: 600;
}

h2{
	color: $grey;
	font-weight: 600;
	font-size: 24px;
	bottom: 20px;
	margin-bottom: 35px;
	margin-top: 10px;
}


/**
 * Global classes
 **/

.read-more-wrapper,
.btn-back-wrapper{
	margin-top: 25px;
}

.read-more-link{
	color: $near-black;
	transition: all .3s ease;

	&:hover{
		color: $green;
	}
}

/**
 * Buttons
 **/

.btn{
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	padding: 10px 25px;

	&:hover{
		color: $white;
	}

	&--back{
		background-color: $red;
		border-radius: 0 20px;
		color: $white;
	}
}


/**
 * Blocks
 **/

.block-50{
	margin-top: 50px;
	margin-bottom: 50px;
}
