/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$grey: #9c9d9e;
$green: #289548;
$red: #c52827;
$grey-light: #eaeaeb;

$white: #FFFFFF;
$black: #000000;
$near-black: #221E1F;

/**
 * Transitions
 **/

$trans1: all ease-in 0.1s;