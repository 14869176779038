/**
 * Search
 **/

 .search-results{
    .intro{
        max-width: 100%;
    }

    .intro__header{
        background-color: $grey-light;
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md){
           height: 125px;
            padding-top: 0px;
           padding-bottom: 0px;			
            
            h1{
                line-height: 125px;
            }
        }
    }

    &__item{
        border-bottom: 2px solid $grey-light;
        padding-bottom: 25px;
        margin-bottom: 25px;

        &:last-of-type{
            border-bottom: 0;
        }
    }
}

.search-template{
    margin-top: 50px;

    h2 a{
        color: $green;
        text-decoration: none;
    }

    p{
        a{
            color: $green;
            text-decoration: none;
            position: relative;

           &:before{
               content:'';
               position: absolute;
               left: 0;
               bottom: -3px;
               background-color: $green;
               width: 100%;
               height: 2px;
           }

           &:after{
               content: '';
               position: absolute;
               background-color: $grey-light;
               transition: $trans1;
               left: 0;
               bottom: -1px;
               width: 100%;
               height: 0px;
               z-index: -1;
           }

           &:hover{
               &:after{
                   height: 100%;
               }
           } 		
        }
    }
}