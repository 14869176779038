/**
 * Page intro
**/

.intro{
	max-width: 670px;

	h1{
		color: $green;
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 35px;
	}
}