/**
 * Language Switch
 **/
 .language-switch-wrapper{
    background-color: $white;	

	select{
		background-color: $grey-light;
		border: 0;
		box-shadow: none;		
		height: 30px;
	}
 }

 .pll-switcher-select{
	float: right;
 }


nav#nav-main{
	padding: 0;
	padding-top: 15px;

	.navbar-brand{
		img{
			max-width: 200px;
		}
	}

	/**
	 * Main nav
	**/
	#menu-entries{
		.navbar-nav{
			margin: 0 0 0 auto;

			.nav-item{
				.nav-link{
					color: $near-black;
					font-size: 14px;
					padding: 0 15px;
					transition: all .3s ease;

					&:hover{
						color: $green;
					}
				}
			}
		}
	}

	/**
	 * Search function
	**/
	.search-wrap{
		div{
			display: inline-block;
		}

		.wp-block-search__input.wp-block-search__input{
			width: 0;
			min-width: auto;
			padding: 0;
			border: 0;
			margin-right: 10px;
			margin-left: 15px;
			font-size: 14px;
			font-weight: 500;

			&:focus{
				outline: none;
			}
		}

		.wp-block-search__input.search-active{
			width: 200px !important;
			transition: width ease-in 0.1s;
			padding: 5px 15px;
		}

		.wp-block-search__button{
			display: none;
		}

		.search-icon{
			cursor: pointer;
			display: inline-block;
			width: 32px;
		}


	}
}


/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}


#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}
