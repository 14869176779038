/**
 * Single template
 **/

.single{
    h1{
        color: $green;
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 35px;
    }
}

