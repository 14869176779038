.content-wrapper{
	padding: 50px 0;
}

.intro-content{
	position: relative;
}

h2.logo-el{
	&::before{
		content: '';
		display: inline-block;
		background: url('images/heading-icon.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 86px;
		height: 76px;
		position: relative;
		top: 25px;
		margin-right: 25px;
	}		
}

.splash-intro{
	h2{
		font-size: 22px;
	}

	p,
	ul{
		margin-left: 112px;
	}
}


.more-news-wrapper{
	margin-top: 60px;
}