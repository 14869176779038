/**
 * Accordion
 **/

 .accordion .accordion{
    margin-top: 25px;
    
    h2.accordion-header .accordion-button{
        font-weight: 500;
    }
}

.accordion-button{
    background-color: $red;
    border-radius: 0 20px 0 0 !important;
    color: $white;
    font-weight: 600;

    &:focus{
        outline: 0 !important;
        box-shadow: none;
    }
}

.accordion-button:not(.collapsed){
    background-color: $grey;
    color: $white;

    &:after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");        
    }
}

.accordion-button.collapsed{
    background-color: $green;

    &:after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");        
    }
}

.accordion-body .accordion-button.collapsed{
    background-color: $grey;
}

.accordion-body{
    padding-top: 1.5rem;
}