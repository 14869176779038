/**
 * Splash
 **/

.splash-wrapper{

    .splash{
        background-color: $grey;
        border-top-right-radius: 100px;
        padding: 50px;
        padding-bottom: 100px;

        &--ap{
            background-color: $red;
        }

        &--nap{
            background-color: $green;
        }

        h2{
            color: $white;
            margin-top: 0;
            padding-top: 0;
        }
    }

    h2{
        text-align: center;
    }

    select,
    input[type="submit"]{
        background-color: $white;
        color: $black;
        height: 60px;
        border: 0;
        box-shadow: none;
    }

    input[type="submit"]{
        width: 29%;
    }

    select{
        width: 70%;
        padding-left: 15px;
    }
}

.splash-intro{
    p{ color: $grey; }
}