/**
 * Footer
 **/

#footer{
    h5{
        color: $near-black;
        margin-bottom: 25px;
    }

    padding-top: 3rem;
    padding-bottom: 3rem;

    ul{
        padding-left: 0;

        li{
            list-style-type: none;

            a{
                color: $near-black;
                text-decoration: none;
                transition: all .3s ease;

                &:hover{
                    color: $green;
                }
            }
        }
    }

}

/**
 * Copyright footer
 **/

.footer-final{
    padding-top: 15px;
    padding-bottom: 15px;

    p,
    a{
        color: $near-black;
    }
}