/**
 * Cards
 **/

.card-col{
    margin-top: 25px;

    .card{
        width: 100%;
        height: 100%;
        text-decoration: none !important;

        .img-wrap{
            display: block;
            height: 250px;
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 0% 17% 0% 100% / 100% 30% 0% 0%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
            }

            &::after{
                content: '';
                width: 100%;
                height: 100%;
                opacity: 0.15;
                z-index: 10;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-blend-mode: multiply;
            }
        }

        .text-wrap{
            height: calc(100% - 250px);
            padding: 10px 15px 15px 15px;
            text-align: center;
            display: table;
            width: 100%;

            span,
            h3{
                color: $white;
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    &:first-of-type,
    &:nth-of-type(6),
    &:nth-of-type(8){
        .card{
            .img-wrap::after, .text-wrap{
                background-color: $green;
            }
        }
    }

    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(9){
        .card{
            .img-wrap::after, .text-wrap{
                background-color: $red;
            }
        }
    }

    &:nth-of-type(3),
    &:nth-of-type(5),
    &:nth-of-type(7){
        .card{
            .img-wrap::after, .text-wrap{
                background-color: $grey;
            }
        }
    }

}