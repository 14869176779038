/**
 * Header
 **/

header{
	background-color: $grey-light;
	//padding-top: 25px;
}

.header-image{
	padding-bottom: 20%;
	background-size: cover;
	background-position: center center;
	margin-top: 25px;
}

.header-slideshow{
	margin-top: 25px;
}